<template>
  <div class="pages p20">
     <!-- <el-alert
        title="warning alert"
        type="warning"
        description="more text description"
        show-icon>
      </el-alert> -->
    <div class="title-btn-block">
      <div class="title-block">{{ $t("message.avance") }}</div>
      <div>
         <el-button class="my-btn " type="warning" @click="take" round>
          {{ $t("message.ask_avance") }}
        </el-button>
        <router-link :to="{ name: 'my_advances' }">
          <div class="my-btn cr1 el-button ml20">
            <i class="el-icon-d-arrow-left"></i> {{ $t("message.back") }}
          </div>
        </router-link>
      </div>
    </div>

    <div class="content-block">
      <div class="datetime-start bg p20 mb20">
        <el-divider content-position="left">{{ $t("message.advance_amount") }}</el-divider>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-input :placeholder="$t('message.summa')" type="number" v-model="form.amount"><template #append>{{ $t("message.summa") }}</template></el-input>
          </el-col>
        </el-row>
      </div>

      <div class="datetime-end bg p20 mb20">
        <el-divider content-position="left">{{ $t("message.data_time") }}</el-divider>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div class="seloction mb20">
              <el-select
                v-model="form.periot"
                :placeholder="$t('message.dedline')"
                class="w-100"
                popper-class="my-data-khan"
              >
                <el-option label="Bugunga" value="today"></el-option>
                <el-option label="Ertaga" value="tomorrow"></el-option>
                <el-option label="Shu haftaga" value="this_week"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :xs="24" >
            <div class="datetime-start-time mb20">
              <span class="label">Qaysi oy hisobidan</span>
              <el-date-picker
                class="w-100"
                v-model="form.month"
                type="month"
                :format="'MMMM yyyy'"
                :value-format="'MM.yyyy'"
                :placeholder="$t('message.day') "
                :picker-options="pickerOptions"
                popper-class="my-data-khan"
              >
              </el-date-picker>
            </div>
          </el-col>
        </el-row>
      </div>

      
      <div class="comment bg p20 mb20">
        <el-divider content-position="left"> {{ $t("message.comment") }} </el-divider>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-input type="textarea" v-model="form.comment"></el-input>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { getItem } from "@/utils/storage";
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["id"],
  data() {
    return {
      region: "",
      value1: "",
      value: "",
      form: {},
      pickerOptions: {
            // disabledDate(time) {
            //             return time.getTime() < Date.now() - 8.64e7;
            //         }
        }
    };
  },
  computed: {
    ...mapGetters({
      list: "assent/list",
      // reasons: "reason/inventory",
    }),
  },
  mounted() {
    // this.reasonsAction();
  },
  methods: {
    ...mapActions({
      // reasonsAction: "reason/inventory",
      updateList: "advance/store",
    }),

    take() {
      this.form.staff_id = getItem("userId");
      this.updateList(this.form)
        .then((res) => {
          console.log('res');
          if (res.status == 201) {
            this.$alert(res);

             this.$message({
              showClose: true,
              type: 'warning',
              duration:10000,
              dangerouslyUseHTMLString: true,
              message: "<b>Sizning avans so'rovingiz 7 kun ichida ko'rib chiqilari!</b>"
            });
            // console.log(res);
            // this.$alert('<strong>This is <i>HTML</i> string</strong>', 'HTML String', {
            //   dangerouslyUseHTMLString: true
            // });

            let route = this.$router.push({
              name: "my_advances"
            });
            window.open(route.href, "_self");
          }
           else {
            this.$alert(res);
          }
        })
        .catch((err) => {
          if(err.status == 202){
            console.log(err);
            this.$message({
              showClose: true,
              type: 'error',
              duration:8000,
              dangerouslyUseHTMLString: true,
              message: err.data.error.message
            });
        
          }
          else{
            this.$alert(err);
          }
        });
    },
  },
};
</script>
<style>
.label{
  color: #fff;
  margin-bottom: 7px;
  display: block;
}
</style>
